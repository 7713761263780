import React from "react";
import { Box, Grid } from "@mui/material";
import { useStyles } from "./Footer.style";
import { Link } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import secult from "assets/img/footer/logosecult.png";
import govestadual from "assets/img/footer/CearaESecretarias.png";
import ufc from "assets/img/footer/UFC.png";
import funcap from "assets/img/footer/Funcap.png";
import lareb from "assets/img/footer/Lareb.png";
import led from "assets/img/footer/Led.png";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Footer: React.FC = () => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ display: "flex", flexDirection: "column"}}>
        <Typography
          sx={{fontSize: "1.5rem", color: "white", marginLeft:"33px" }}
        >
          Realização:
        </Typography>
        <Box sx={styles.support}>
           <Box sx={{ transform: "scale(1.2)", marginTop:"18px", marginLeft:"5px" }}>
            <img src={ufc} alt="logo ufc" />
          </Box>
          <Box sx={{ transform: "scale(1.2)",marginTop:"12px" }}>
            <img src={led} alt="logo LED" />
          </Box>
          <Box sx={{ transform: "scale(1.2)",marginTop:"12px" }}>
            <img src={lareb} alt="logo Lareb" />
          </Box>
          <Box sx={{ transform: "scale(0.5)",marginTop:"16px" }}>
            <img src={funcap} alt="logo funcap" />
          </Box>
          <Box sx={{ transform: "scale(1.2)", marginTop:"25px"}}>
            <img src={govestadual} alt="logo Governo" />
          </Box>

      </Box>
    </Box>
    </Box>
  );
};

{
  /* <Box sx={styles.button}>
          <Typography sx={styles.text}>Cientista Chefe na Rede</Typography>
          <Box>
              <Link
                href="https://www.facebook.com/cientistachefedacultura"
                target="_blank"
              >
                <FacebookIcon sx={{marginRight: "0.5rem", fontSize: "2.5rem"}}/>
              </Link>
              <Link
                href="https://www.instagram.com/cientistachefedacultura/"
                target="_blank"
              >
                <InstagramIcon sx={{fontSize: "2.5rem"}} />
              </Link>
            </Box>
        </Box> */
}
